import React from 'react';
import {Button, FormGroupList, Input, FormGroup, Textarea} from 'tyb';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import { FormGroupField } from 'tyb';
const { getZone } = iceStarkData.store.get('commonAction')?.zone;
const { createFactory, editFactory, loadFactoryById } = iceStarkData.store.get('commonAction')?.factory;
import './factoryCreateForm.scss';
import { NewSelect } from 'ucode-components';
const factoryTypeFilter = [
  {text: '包材印刷工厂', value: 'PRINT'},
  {text: '成品灌装工厂', value: 'PACKAGE'},
];

const validate = values => {
  const errors = {};
  if (values.code && values.code.trim().length > 50) {
    errors.code = "长度不超过50个字符";
  }
  if (!values.name || values.name && values.name.trim().length == 0) {
    errors.name = "请输入工厂名称";
  }
  if (values.name && values.name.trim().length > 30) {
    errors.name = "长度不超过30个字符";
  }
  if (!values.provinceCode) {
    errors.provinceCode = "请选择省";
  }
  // if (!values.cityCode) {
  //   errors.cityCode = "请选择市";
  // }
  // if (!values.countyCode) {
  //   errors.countyCode = "请选择区";
  // }
  if (values.address && values.address.trim().length > 50) {
    errors.address = '长度不超过50个字符';
  }
  if (!values.factoryType) {
    errors.factoryType = '请选择工厂类型';
  }
  return errors
};


@connect (
  state => {
    return {
      form: state.form,
      ...state.zone,
      ...state.factory,
    };
  },
  {
    getZone: getZone.REQUEST,
    createFactory: createFactory.REQUEST,
    editFactory: editFactory.REQUEST,
    loadFactoryById: loadFactoryById.REQUEST,
  }
)
class FactoryCreateForm extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      cityTree: [],
      countyTree: [],
    };
  }
  componentDidMount () {
    const { dataId, zoneList = [], loadFactoryById } = this.props;
    // 迎驾贡注释
    // if (zoneList.length < 1) {
    //   this.props.getZone();
    // }
    if (dataId) {
      loadFactoryById({
        id: dataId, callback: (res) => {
          this.initForm(res);
        }
      });
    }
    
  };
  componentDidUpdate(prevProps, prevState) {
    const { factoryDetail = {} } = this.props;
    if (Object.keys(factoryDetail).length > 0) {
      if (prevProps.zoneList.length == 0 && this.props.zoneList.length > 0) {
      this.initForm(factoryDetail);
    } 
    }
  };
  initForm = (res) => {
      const { form = {}, initialize } = this.props;
      const { provinceCode = '', cityCode = '' } = res;
      if (provinceCode) {
          this.provinceCodeChange(provinceCode, 2, 'cityTree');
      }
      if (cityCode) {
          this.provinceCodeChange(cityCode, 3, 'countyTree');
      }
      initialize({
          ...form.factoryCreateForm.values,
          ...res
      });
  }

 // 统一去前后空格
 trims = obj => {
  for (let key in obj) {
    if(obj[key]==null){
    }
    else if (obj[key].constructor === String) {
      obj[key] = obj[key].trim();
    } else if (obj[key].constructor === Object) {
      this.trims(obj[key]);
    }
  }
}

  handleSubmit = data => {
    const {createFactory, editFactory, handleClose} = this.props;
    data.provinceCode = '-1' // 迎驾贡添加
    this.trims (data);
    if (data.id) { editFactory({data, callback: handleClose}); } else { createFactory({data, callback: handleClose}); }
  };

  // 选择省，加载市级树
  provinceCodeChange = (value, level, tree) => {
    const { zoneList = [], initialize, form: { factoryCreateForm = {} } } = this.props;
    initialize({ ...factoryCreateForm.values, countyCode: '', cityCode: level == 2 ? '' : value, });
    const newTreeData = zoneList.filter(v => v.parentCode == value && v.level == level);
    let preSetData = {};
    // 没有二级
    if (level == 2 && newTreeData.length == 0) {
      preSetData = { countyTree: [], cityTree: [], countyTree: zoneList.filter(v => v.parentCode == value) }
    } else {
      preSetData = { countyTree: [], [tree]: newTreeData }
    }
    this.setState (preSetData);
  };

  render () {
    const { handleClose, handleSubmit, dataId} = this.props;
    const { zoneList, form: { factoryCreateForm = { value: {provinceCode: '', cityCode: '', countyCode: ''}, }, }, } = this.props;
    const {cityTree, countyTree} = this.state;
    if (!factoryCreateForm.values) { factoryCreateForm.values = { provinceCode: '', cityCode: '', countyCode: '', }; }
    return (
      <div className="factory-form">
        <FormGroupList>
          
        <FormGroupField
            name={'code'}
            label="工厂编码"
            control={Input}
            component={FormGroup}
            placeholder="输入工厂编码"
          />
          <FormGroupField
            name={'name'}
            label="工厂名称"
            control={Input}
            component={FormGroup}
            required
            placeholder="请输入工厂名称"
          >
            <span className="remark-text">长度不超过30个字符</span>
            </FormGroupField>
        </FormGroupList>
        {/* 迎驾贡注释 */}
        {/* <FormGroupList>
          <FormGroupField
            className="factoryArea-box"
            name={'provinceCode'}
            label="工厂区域"
            control={NewSelect}
            list={zoneList.filter (v => v.level == 1)}
            component={FormGroup}
            style={{width:100}}
            required
            onChange={value => { this.provinceCodeChange (value, 2, 'cityTree'); }}
            placeholder="请选择省"
          />
          <FormGroupField
            className="factoryArea-box"
            name={'cityCode'}
            control={NewSelect}
            list={cityTree}
            component={FormGroup}
            style={{width:100}}
            onChange={value => {
              this.provinceCodeChange (value, 3, 'countyTree');
            }}
            placeholder="请选择市"
          />
          <FormGroupField
            className="factoryArea-box"
            name={'countyCode'}
            control={NewSelect}
            style={{width:100}}
            list={countyTree}
            component={FormGroup}
            placeholder="请选择区"
          />
        </FormGroupList> */}
        <FormGroupList>
          {/* <FormGroupField
            name={'address'}
            label="工厂地址"
            control={Textarea}
            component={FormGroup}
            placeholder="输入详细地址"
            text="长度不超过50个字符"
          /> */}
          <FormGroupField
            name={'factoryType'}
            label="工厂类型"
            control={NewSelect}
            list={factoryTypeFilter}
            component={FormGroup}
            required
            disabled={dataId ? true : false }
            placeholder="选择工厂类型"
            style={{width:330}}
          />
        </FormGroupList>
        <div className="button-box">
          <Button className='' onClick={ handleSubmit(this.handleSubmit) }>提交</Button>
          <Button className='weak' onClick={handleClose}>取消</Button>
        </div>
      </div>
    );
  }
}
export default reduxForm ({
  form: 'factoryCreateForm',
  validate
}) (FactoryCreateForm);
